<template>
  <!-- 承接中 货单跟踪 1qx 货源找车2xw（货主确认） -->
  <div class="carrier-report-wrap">
    <div class="flex-c mb20">
      <el-col :span="5">
        <el-input v-model="form.truckNo" placeholder="输入车牌号/姓名">
          <i slot="suffix" class="cursor hover-main el-input__icon el-icon-search" @click="search" />
        </el-input>
      </el-col>
    </div>
    <div class="flex mb20">
      <el-col :span="5" class="flex-c">
        <div class="label bold-text">总计： </div>
        <div class="value"> <span class="main-c">{{ pagination.total||"-" }} </span>车</div>
      </el-col>
    </div>
    <div class="g-search-form-table">
      <Table
        v-loading="loading"
        :table-cols="tableCols"
        :table-data="tableData"
        :show-pagination="true"
        :pagination="pagination"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'price'" class="ellipsis">
            <span class="mr5">{{ $parseNumber(row.price) }} {{ $CONSTANT.transportPriceUnitMap.O[row.priceUnit] }}</span>
            <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'infoPrice'" class="ellipsis">
            <span>{{ +row.msgPriceType===2?$parseNumber(row.msgPrice):'-' }}元/车</span>
          </div>
          <div v-if="col.prop === 'truckNo'" class="ellipsis">
            <span class="mr5">{{ row.truckNo||'-' }}</span>
            <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="mr5">{{ row.driverName||'-' }}</span>
            <span>{{ row.driverPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'sourceType'" class="ellipsis">
            <span>平台定向</span>
          </div>
        </template>
      </Table>
    </div>
    <BandDialog v-if="dialogVisible" :dialog-visible.sync="dialogVisible" :car-order-id="clickOrderId" :show-action="false" @savePound="savePound" />
  </div>
</template>

<script>
import goods from '@/api/transport/goods';
import BandDialog from '@/components/OrderBusiness/bandDialog';
import { numberCalculation } from '@/utils';
export default {
  components: { BandDialog },
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
  },

  data() {
    return {
      numberCalculation,
      form: {},
      loading: false,
      tableCols: [
        { label: '车牌号', prop: 'truckNo', type: 'customize', fixed: 'left' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160, fixed: 'left' },
        { label: '承运人', type: 'userInfoCard', width: 165, userType: 2 },
        { label: '上报价格', prop: 'price', width: 138, type: 'customize' },
        { label: '信息费', prop: 'infoPrice', type: 'customize' },
        { label: '预计利润（元/车）', prop: 'profit', type: 'numberTransition', multiple: 100, width: 138 },
        { label: '货主确认时间', prop: 'confirmTime', type: 'filterTime', width: 165 },
        { label: '取消时间', prop: 'confirmTime', type: 'filterTime', width: 165 },
        { label: '补偿金额（元）', prop: 'ext3', type: 'numberTransition', multiple: 100 },
        { label: '车单状态', prop: 'state', type: 'transition', rulesObj: this.$CONSTANT.carOrderStateMap.O, rulesColorObj: { 0: 'grey-c', 1: 'grey-c', 2: 'grey-c', 3: 'green-c', 4: 'green-c', 5: 'green-c', 6: 'main-c', 7: 'main-c', 8: 'main-c', 9: 'main-c', 10: 'main-c' }},
        { label: '报车渠道', prop: 'sourceType', type: 'customize', rulesObj: this.$CONSTANT.sourceMap },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
        { label: '车单编号', prop: 'carSerial', width: 180 },
        { label: '操作', type: 'operation', fixed: 'right', align: 'left',
          btns: [
            {
              label: '查看磅单',
              evt: (data) => {
                this.openDialog(data);
              },
            },
          ],
        },
      ],
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      dialogVisible: false,
      bandInfo: {},
      clickOrderId: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      const params = {
        search: this.form.truckNo,
        gId: this.orderId,
        state: '4,6', // state 3,5,7,8,9 已确认 4,6取消
        post: this.$store.state.common.AUV_ROLE_CODE,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      goods.orderCarTransGoodsList(params).then(res => {
        this.tableData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    search() {
      this.pagination.page = 1;
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    openDialog(data) {
      this.clickOrderId = data.id;
      this.dialogVisible = true;
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .g-table-c{
    padding: 0;
  }
  .bold-text{
    font-weight: bold;
    // font-size: 16px;
    color: $Black;
  }
  .g-search-form-table{
    margin-bottom: 30px;
    width: 100%;
  }
}
</style>
